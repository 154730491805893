import * as React from 'react';

import {
    AppBar,
    Tab,
    Tabs
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';


export interface TabDescriptor {
    label: string;
    data?: unknown;
    className?: string;
    disabled?: boolean;
}

export enum TabControlOrientation {
    Horizontal = 0,
    Vertical
}

export type TabControlColor = 'primary' | 'secondary' | undefined;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0'
    },
    tabContentContainer: {
        flex: '1 0 0'
    },
    tabContentVerticalContainer: {
        flex: '1 0 0',
        overflow: 'auto'
    },
    verticalTabRoot: {
        display: 'flex',
        overflow: 'auto'
    },
    verticalTabContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    verticalTabIndicator: {
        backgroundColor: 'transparent'
    },
    paper: {
        zIndex: 'auto'
    }
});

interface Props extends WithStyles<typeof styles> {
    tabsDescriptors: TabDescriptor[];
    selectedTab?: number;
    selectedTabLineColor: TabControlColor;
    selectedTabColor: TabControlColor;
    orientation: TabControlOrientation;
    children?: React.ReactNode;
    onTabChange?: (selectedTabIndex: number, selectedTab: TabDescriptor) => void;
}

interface States {
    selectedTab: number;
}

class TabControl extends React.Component<Props, States> {
    public static defaultProps = {
        orientation: TabControlOrientation.Horizontal,
        selectedTabLineColor: 'primary' as TabControlColor,
        selectedTabColor: 'primary' as TabControlColor
    };

    state = {
        selectedTab: this.props.selectedTab ? this.props.selectedTab : 0
    };
 
    getEnabledTab = memoize(
        (tabsDescriptors: TabDescriptor[]): number => {
            return tabsDescriptors.findIndex((descriptor: TabDescriptor) =>{
                return !descriptor.disabled;
            });
        });

    getTabsDescriptors = memoize((tabsDescriptors: TabDescriptor[]) => {
        return tabsDescriptors.map((descriptor: TabDescriptor, index: number) => {
            const {
                label,
                className,
                disabled
            } = descriptor;
            return (
                <Tab
                    className={className}
                    key={`${index}-${label}`}
                    label={label}
                    disabled={!!disabled}
                />
            );
        });
    })

    onTabClicked = (event: React.ChangeEvent<Record<string, unknown>>, selectedTab: number) => {
        const { selectedTab: selectedTabState } = this.state;
        if (selectedTab === selectedTabState) {
            return;
        }

        const { onTabChange } = this.props;
        if (onTabChange) {
            const { tabsDescriptors } = this.props;

            onTabChange(selectedTab, tabsDescriptors[selectedTab]);
        }

        this.setState({ selectedTab });
    };

    public render() {
        const {
            classes,
            children,
            selectedTab: selectedTabProp,
            tabsDescriptors,
            selectedTabLineColor,
            selectedTabColor,
            orientation
        } = this.props;
        const {
            selectedTab: selectedTabState
        } = this.state;
        const currentSelectedTab = selectedTabProp !== undefined ? selectedTabProp : selectedTabState;
        const selectedTab =
            tabsDescriptors ?
                (!tabsDescriptors[currentSelectedTab].disabled) ?
                    currentSelectedTab
                    : this.getEnabledTab(tabsDescriptors)
                : -1;
        const currentTabContent =
            children ? 
                children instanceof Array ?
                    children[selectedTab]
                    : children
                : '';
        const isVertical = orientation === TabControlOrientation.Vertical;
        
        return (
            <div className={`${(!isVertical) ? classes.root : classes.verticalTabRoot }`}>
                {!isVertical ?
                    <AppBar 
                        className={classes.paper}
                        position="static"
                        color="default"
                    >
                        <Tabs
                            value={selectedTab}
                            onChange={this.onTabClicked}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor={selectedTabLineColor}
                            textColor={selectedTabColor}
                        >
                            {
                                this.getTabsDescriptors(tabsDescriptors)
                            }
                        </Tabs>
                    </AppBar>
                    :
                    <Tabs
                        value={selectedTab}
                        onChange={this.onTabClicked}
                        indicatorColor={selectedTabLineColor}
                        textColor={selectedTabColor}
                        classes={{
                            flexContainer: classes.verticalTabContainer,
                            indicator: classes.verticalTabIndicator
                        }}
                    >
                        {
                            this.getTabsDescriptors(tabsDescriptors)
                        }
                    </Tabs>
                }
                <div className={`${(!isVertical) ? classes.tabContentContainer : classes.tabContentVerticalContainer}`}>
                    {
                        currentTabContent ? currentTabContent : ''
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(TabControl);
export {MUIComponent as TabControl};
