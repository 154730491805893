import { Account } from '../shared/domain';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export const STATIC_PROGRESS_LIMIT = 5;

export interface JobRequest {
    job_type: string;
    user_created_id: string;
    account_id: string;
    additional_params: any;
}

export interface JobScheduleResult {
    status: number;
    job_id: string;
    error?: string;
    stack_trace?: string;
}

export interface JobStatusResult {
    job_id: string;
    job_status: StatusTypes;
    estimate_percent_complete: number;
    error?: string;
    stack_trace?: string;
}

export interface JobErrorResult {
    error: string;
    stack_trace: string;
}

export interface JobResult {
    job_id: string;
    result: unknown;
    error?: string;
    stack_trace?: string;
}

export interface TaskGroup {
    id: string,
    name: string,
    progress: number,
    startedAt: number,
    elapsedTime: number,
    total: number,
    done: string[],
    errored: string[],
    tasks: RunningTasks[]
}

// JOBS HAVE BEEN CHANGED FROM INTERVALS TO TIMEOUTS:
// - BETTER PERFORMANCE
// - REDUCE LOAD ON STATUS ENDPOINT
// - CATCH STATUS TIMEOUTS
// - BETTER MANAGE SLOW JOBS
export interface RunningTasks {
    account: Account | null,
    export_name: string,
    export_id: string,
    task_id: string,
    status: number,
    type: number,
    file_url?: string,
    startedAt: number,
    elapsedTime: number,
    progress: number,
    result_data?: unknown,
    group_id?: string,
    set_to_stop: boolean,
    timer: number,
    static_progress_count: number,
    internalCallback?: (taskId: string) => void,
}

export const JobTypes = {
    Test: 0,
    FolderExport: 10,
    FloorplanExport: 11,
    TeamUserExport: 12,
    QRCodeGeneration: 30,
    GenerateFloorplan: 100,
    ReGenerateMarkerMinimap: 104,
    MarkerOperations: 106,
    MarkerMetaBulkUpdates: 108,
    MarkerModuleGroupBulkUpdates: 109,
    DownloadFloorplans: 201,
    DownloadFloorMaps: 202,
    ThinClientConvertion: 204,
    DeleteFloorplan: 206,
    DeleteFolder: 210,
    BulkUserCreation: 302
}

export enum StatusTypes {
    Enqueued = 'ENQUEUED',
    Runnint = 'RUNNING',
    Error = 'ERROR',
    Complete = 'COMPLETE'
}

export default class JobProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}jobs`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    scheduleJob = (request: JobRequest) => {
        const formData = new FormData();
        formData.append('job_type', request.job_type);
        formData.append('user_created_id', request.user_created_id);
        formData.append('account_id', request.account_id);
        formData.append('additional_params', JSON.stringify(request.additional_params));
        return this.init().postWithCredentials(`${this.endpointAdmin}/job`, formData).then(response => {
            const { job_id, error, stack_trace } = response.data;
            const statusCode = response.status;

            return { status: statusCode, job_id, error, stack_trace } as JobScheduleResult;
        });
    }

    checkJobStatus = (jobId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/job/${jobId}/status`).then(response => {
            const statusCode = response.status;
            const {
                job_id,
                job_status,
                estimate_percent_complete,
                error,
                stack_trace
            } = response.data;

            return {
                status: statusCode,
                job_id,
                job_status,
                estimate_percent_complete,
                error,
                stack_trace
            } as JobStatusResult;
        });
    }

    checkJobError = (jobId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/job/${jobId}/error`).then(response => {
            const statusCode = response.status;
            const {
                error,
                stack_trace
            } = response.data;

            return {
                status: statusCode,
                error,
                stack_trace
            } as JobErrorResult;
        });
    }

    checkJobResult = (jobId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/job/${jobId}/results`).then(response => {
            const statusCode = response.status;
            const {
                job_id,
                result,
                error,
                stack_trace
            } = response.data;

            return {
                status: statusCode,
                job_id,
                result,
                error,
                stack_trace
            } as JobResult;
        });
    }
}