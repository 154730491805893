import { HttpConnectorAdapter } from '../adapters/http-connector-adapter';

export interface MagicUrlForm {
    user_id: string;
    account_id: string;
    team_id: string;
    ttl: number;
}

export default class MagicURLProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}magic_url`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };
    
    createMagicUrl = (magicUrl: MagicUrlForm) => {
        const formData = new FormData();
        formData.append('user_id', magicUrl.user_id);
        formData.append('account_id', magicUrl.account_id);
        formData.append('team_id', magicUrl.team_id);
        formData.append('ttl', `${magicUrl.ttl}`);
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, formData).then(response => {
            const { url } = response.data;
            const statusCode = response.status;

            return { status: statusCode, url };
        });
    }

}