import {
    IconButton,
    Menu,
    MenuItem,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { AccountCircle, MenuRounded } from '@material-ui/icons';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { CCSnackbar } from '../../shared/components/cc-snackbar';
import { HamburgerControl, MenuDescriptor } from '../../shared/components/hamburger-control';
import { SimpleDialog } from '../../shared/components/simple-dialog';
// import { TabControl } from '../../shared/components/tab-control';
import { versionHelper } from '../../shared/utils';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';
import { ROUTE_NAMES } from '../../stores/routes';
import { Accounts } from '../accounts';
import { AddRemoveTeam } from '../add-remove-team';
import { Folders } from '../folders';
import { MarkerDetail } from '../marker-detail';
import { MarkerExport } from '../marker-export';
import { MarkerOperations } from '../marker-operations';
import { Markers } from '../markers';
import { ModuleGroups } from '../module-groups/module-groups';
import { QRCodes } from '../qr-codes';
import { TeamInfo } from '../team-info';
import { Teams } from '../teams';
import { UserExport } from '../user-export';
import { FloorplanReplace } from '../floorplan-replace';
import { SchedulingPermissions } from '../scheduling-permissions';
import { MagicURL } from '../magic-url';
import { UserCreation } from '../user-creation';

interface NameToValueMap {
    [key: string]: any;
}

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0.5em 1.8em 0.5em 1em',
        boxShadow: theme.shadows[5],
        alignItems: 'center'
    },
    accountMenuButton: {
        marginLeft: '1em',
        marginRight: '1em',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)'
        }
    },
    hamburguerMenuButton: {
        marginRight: '1em',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)'
        }
    },
    logo: {
        alignSelf: 'flex-end',
        position: 'absolute',
        top: '0.9em',
        right: '1.2em',
        width: '3em',
        height: '3.2em'
    },
    snackBar: {
        alignItems: 'flex-start',
        top: '1em',
        right: 0
    },
    snackBarMessage: {
        fontWeight: 'bolder',
        textAlign: 'end'
    },
    tabContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto',
        padding: '0 1.8em',
        margin: '0.5em 0'
    },
    title: {
        marginBottom: '0'
    },
    subtitle: {
        fontSize: '0.8em',
        fontWeight: 'bold',
        textAlign: 'right',
        marginBottom: '0.5em'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface States {
    hamburgerMenuOpen: boolean;
    accountMenuOpen: boolean;
    accountMenuAnchorElement: HTMLElement | null;
    menuDescriptors: MenuDescriptor[];
    selectedMenu: MenuDescriptor;
    isAuthErrorModalOpened: boolean;
}

@inject('rootStore')
@observer
class Home extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accountMenuOpen: false,
        accountMenuAnchorElement: null,
        hamburgerMenuOpen: false,
        menuDescriptors: [
            { label: 'User Info' },
            { label: 'Floor Plans' },
            { label: 'Floor Plan Replace' },
            { label: 'QR Codes' },
            { label: 'Marker Export' },
            { label: 'Team Info' },
            { label: 'User Export' },
            { label: 'Marker Operations' },
            { label: 'Marker Detail' },
            { label: 'Teams' },
            { label: 'Folders' },
            { label: 'Accounts' },
            { label: 'Module Groups' },
            { label: 'Scheduling Permissions' },
            { label: 'Magic URL (Experimental, Not for use)' },
            { label: 'Bulk User Creation' }
        ],
        selectedMenu: {} as MenuDescriptor,
        isAuthErrorModalOpened: false
    };

    componentDidMount() {
        this.checkCurrentPage();
    };

    checkCurrentPage = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const { menuDescriptors } = this.state;
        const currentTabIndex = adminStore.currentTab;
        const currentTab = menuDescriptors[currentTabIndex];

        if (currentTab) {
            this.setState({ selectedMenu: currentTab });
        }
    };

    setAccountMenuState = (isOpen: boolean) => (event: React.MouseEvent<HTMLElement>) => {
        let accountMenuAnchorElement;
        if (isOpen) {
            accountMenuAnchorElement = event.currentTarget;
        } else {
            accountMenuAnchorElement = null;
        }
        this.setState({
            accountMenuOpen: isOpen,
            accountMenuAnchorElement
        });
    };

    setHamburgerMenuState = () => {
        const { hamburgerMenuOpen } = this.state;
        this.setState({ hamburgerMenuOpen: !hamburgerMenuOpen });
    }

    getMainTabFromIndex(selectedTabIndex: number): MainTabs {
        switch (selectedTabIndex) {
            case 1:
                return MainTabs.Markers;
            case 2:
                return MainTabs.Floorplan_Replace;
            case 3:
                return MainTabs.QR_Code;
            case 4:
                return MainTabs.Marker_Export;
            case 5:
                return MainTabs.Team_Info;
            case 6:
                return MainTabs.User_Export;
            case 7:
                return MainTabs.Marker_Operations;
            case 8:
                return MainTabs.Marker_Detail;
            case 9:
                return MainTabs.Teams;
            case 10:
                return MainTabs.Folders;
            case 11:
                return MainTabs.Accounts;
            case 12:
                return MainTabs.Module_Groups;
            case 13:
                return MainTabs.Scheduling_Permissions;
            case 14:
                return MainTabs.MagicURL;
            case 15:
                return MainTabs.User_Creation;
            case 0:
            default:
                return MainTabs.AccountInfo;
        }
    }

    getMainTabIndexFromValue(value: MainTabs): number {
        const converter = MainTabs as NameToValueMap;
        const key = MainTabs[value];
        return converter[key];
    }

    //#region Events

    onLogoutClicked = () => {
        this.setState({
            accountMenuOpen: false,
            isAuthErrorModalOpened: false
        });
        const {
            rootStore: { adminStore }
        } = this.props;

        adminStore.logout();
    };

    onTabChanged = (selectedTabIndex: number, selectedMenu: MenuDescriptor) => {
        const {
            rootStore: { routerStore }
        } = this.props;
        const currentTab = this.getMainTabFromIndex(selectedTabIndex);

        if (selectedMenu) {
            this.setState({ selectedMenu });
        }
        switch (currentTab) {
            case MainTabs.Markers:
                routerStore.goTo(ROUTE_NAMES.MARKERS);
                break;
            case MainTabs.QR_Code:
                routerStore.goTo(ROUTE_NAMES.QR_CODES);
                break;
            case MainTabs.Marker_Export:
                routerStore.goTo(ROUTE_NAMES.MARKER_EXPORT);
                break;
            case MainTabs.Team_Info:
                routerStore.goTo(ROUTE_NAMES.TEAM_INFO);
                break;
            case MainTabs.User_Export:
                routerStore.goTo(ROUTE_NAMES.USER_EXPORT);
                break;
            case MainTabs.Marker_Operations:
                routerStore.goTo(ROUTE_NAMES.MARKER_OPERATIONS);
                break;
            case MainTabs.Marker_Detail:
                routerStore.goTo(ROUTE_NAMES.MARKER_DETAIL);
                break;
            case MainTabs.Teams:
                routerStore.goTo(ROUTE_NAMES.TEAMS);
                break;
            case MainTabs.Folders:
                routerStore.goTo(ROUTE_NAMES.FOLDERS);
                break;
            case MainTabs.Accounts:
                routerStore.goTo(ROUTE_NAMES.ACCOUNTS);
                break;
            case MainTabs.Floorplan_Replace:
                routerStore.goTo(ROUTE_NAMES.FLOORPLAN_REPLACE);
                break;
            case MainTabs.Module_Groups:
                routerStore.goTo(ROUTE_NAMES.MODULE_GROUPS);
                break;
            case MainTabs.Scheduling_Permissions:
                routerStore.goTo(ROUTE_NAMES.SCHEDULING_PERMISSIONS);
                break;
            case MainTabs.MagicURL:
                routerStore.goTo(ROUTE_NAMES.MAGIC_URL);
                break;
            case MainTabs.User_Creation:
                routerStore.goTo(ROUTE_NAMES.USER_CREATION);
                break;
            default:
                routerStore.goTo(ROUTE_NAMES.HOME);
                break;
        }
    };

    onSnackbarClosed = () => {
        const {
            rootStore: { adminStore }
        } = this.props;

        adminStore.loadingError = '';
    };

    onAuthError = () => {
        this.setState({ isAuthErrorModalOpened: true });
    };

    //#endregion

    public render() {
        const {
            classes
        } = this.props;
        const {
            accountMenuOpen,
            accountMenuAnchorElement,
            hamburgerMenuOpen,
            menuDescriptors,
            selectedMenu,
            isAuthErrorModalOpened
        } = this.state;
        const {
            rootStore: { adminStore }
        } = this.props;
        const {
            currentTab,
            loadingError
        } = adminStore;

        return (
            <div className={classes.container}>
                <div className={`${classes.logo} cc-logo-no-words`} />
                <div className={classes.titleContainer}>
                    <IconButton
                        className={classes.hamburguerMenuButton}
                        color="inherit"
                        onClick={this.setHamburgerMenuState}
                    >
                        <MenuRounded />
                    </IconButton>
                    <div>
                        <h1 className={classes.title}>Admin Tool</h1>
                        <Typography className={classes.subtitle} variant="h6">
                            V{versionHelper.getVersionNumer()}
                        </Typography>
                    </div>
                    <IconButton
                        className={classes.accountMenuButton}
                        aria-owns={accountMenuOpen ? 'menu-account' : undefined}
                        aria-haspopup="true"
                        onClick={this.setAccountMenuState(true)}
                        color="inherit"
                        disableRipple={true}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-account"
                        anchorEl={accountMenuAnchorElement}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        open={accountMenuOpen}
                        onClose={this.setAccountMenuState(false)}
                    >
                        <MenuItem onClick={this.onLogoutClicked}>
                            Logout
                        </MenuItem>
                    </Menu>
                    {
                        selectedMenu &&
                        <Typography variant="h5">
                            {selectedMenu.label}
                        </Typography>
                    }
                </div>
                <HamburgerControl
                    menuDescriptors={menuDescriptors}
                    classes={{
                        menuContentContainer: classes.tabContentContainer
                    }}
                    menuOpened={hamburgerMenuOpen}
                    selectedMenu={this.getMainTabIndexFromValue(currentTab)}
                    selectedMenuColor={"primary"}
                    variant={"temporary"}
                    menuDirection={"left"}
                    onMenuToggle={this.setHamburgerMenuState}
                    onMenuChange={this.onTabChanged}
                >
                    <AddRemoveTeam
                        onAuthError={this.onAuthError}
                    />
                    <Markers
                        onAuthError={this.onAuthError}
                    />
                    <FloorplanReplace
                        onAuthError={this.onAuthError}
                    />
                    <QRCodes
                        onAuthError={this.onAuthError}
                    />
                    <MarkerExport
                        onAuthError={this.onAuthError}
                    />
                    <TeamInfo
                        onAuthError={this.onAuthError}
                    />
                    <UserExport
                        onAuthError={this.onAuthError}
                    />
                    <MarkerOperations
                        onAuthError={this.onAuthError}
                    />
                    <MarkerDetail
                        onAuthError={this.onAuthError}
                    />
                    <Teams
                        onAuthError={this.onAuthError}
                    />
                    <Folders
                        onAuthError={this.onAuthError}
                    />
                    <Accounts
                        onAuthError={this.onAuthError}
                    />
                    <ModuleGroups
                        onAuthError={this.onAuthError}
                    />
                    <SchedulingPermissions
                        onAuthError={this.onAuthError}
                    />
                    <MagicURL
                        onAuthError={this.onAuthError}
                    />
                    <UserCreation
                        onAuthError={this.onAuthError}
                    />
                </HamburgerControl>
                <CCSnackbar
                    className={classes.snackBar}
                    classes={{ message: classes.snackBarMessage }}
                    open={loadingError.length > 0}
                    showCloseButton={true}
                    transition="right"
                    variant="error"
                    message={loadingError}
                    onClose={this.onSnackbarClosed}
                />
                <SimpleDialog
                    open={isAuthErrorModalOpened}
                    titleText="Your session has expired"
                    contentText="Your session has ended you will be redirected to log back in again"
                    buttonCancelLabel=''
                    onDialogResult={this.onLogoutClicked}
                    testId="authErrorModal"
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Home);
export { MUIComponent as Home };
