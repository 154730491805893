/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'ag-grid-enterprise';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import * as React from 'react';
import _ from 'lodash';
import { AccountFull } from '../../models/account';
import { Team } from '../../shared/domain';
import {
    Button,
    ButtonGroup,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import { CCSpinner } from '../../shared/components/cc-spinner';
import {
    AddOutlined,
    CheckOutlined,
    DeleteRounded,
    ErrorOutline,
    RemoveOutlined
} from '@material-ui/icons';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import {
    AgGridList,
    ValidAgGridRowModel,
    ValidGridRefData
} from '../../components/ag-grid-list/ag-grid-list';
import { CustomCellRendererProps } from 'ag-grid-react';
import {
    ColDef,
    GridOptions,
    ProcessCellForExportParams,
    ProcessDataFromClipboardParams,
    ValueParserParams,
    ValueSetterParams
} from 'ag-grid-community';
import { IRichCellEditorParams } from 'ag-grid-enterprise';
import { UserPayloadEntry } from '../../providers/user.provider';

export interface EmailInputField {
    email: string;
    lastValidation: boolean;
}

export interface newUserFormField {
    actions: string;
    email: EmailInputField;
    fname: string;
    lname: string;
    password: string;
    team: Team;
}

const EMPTY_TEAM: Team = {
    _id: 'no-team',
    access_code: '',
    accounts: [],
    actions: {
        access: {},
        common: {},
        meta: {}
    },
    channels: [],
    excluded_folders: [],
    filters: [],
    folders: [],
    members: {},
    module_groups: {
        read: {},
        write: {}
    },
    name: 'Select a team',
    site_notifications: false,
    structures: [],
    team_id: 'no-team',
    team_profile: [],
    type: 'team',
    timestamp: ''
};

const PLACEHOLDDER_ITEM: newUserFormField = {
    actions: '',
    email: { email: '', lastValidation: false },
    fname: '',
    lname: '',
    password: '',
    team: EMPTY_TEAM
};

const styles = (theme: Theme) =>
    createStyles({
        [theme.breakpoints.down('lg')]: {
            simpleList: {
                minWidth: '32em'
            }
        },
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em',
            marginTop: '1em'
        },
        ellipsisDetails: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'visible'
        },
        simpleList: {
            height: '100%',
            minWidth: '35em'
        },
        textField: {
            padding: '1em',
            width: '100%',
            '& label': {
                fontWeight: 'bold',
                padding: '1em 0 0 1em',
                // fontSize: '1.4em',
                color: 'rgba(0, 0, 0, 0.8) !important'
            },
            '&::before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderBottomStyle: 'solid'
            }
        },
        simpleListTeams: {
            width: '36em'
        },
        teamSpinnerContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '3em',
            marginBottom: '4em',
            borderRadius: '0.4em'
        },
        noAccountContainer: {
            fontWeight: 'bold',
            '& > *': {
                color: 'rgba(0,0,0,0.2)'
            }
        },
        stretchContainer: {
            flex: '1',
            transition: 'width 1s'
        },
        buttonContainer: {
            marginTop: '1.5em',
            marginBottom: '0.5em'
        },
        buttonTitle: {
            display: 'inline',
            float: 'left'
        },
        buttonExport: {
            float: 'right'
        },
        buttonImport: {
            float: 'right',
            marginRight: '3em'
        },
        treePaper: {
            height: '100%'
        },
        selectInput: {
            marginTop: '1em',
            marginRight: '0.5em'
        },
        dividerForm: {
            margin: '0 1em'
        },
        treeHeader: {
            padding: '1em',
            margin: '1em 0'
        },
        formHeaderGroup: {
            alignItems: 'center',
            float: 'right',
            '& button': {
                border: 'unset !important'
            }
        },
        sizeInput: {
            width: '2em',
            maxWidth: '4em',
            marginLeft: '0.5em',
            '& input': {
                textAlign: 'center !important'
            },
            '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            // Remove spinner buttons for Firefox
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            }
        },
        itemLabel: {
            marginRight: '0.5em'
        },
        input: {
            display: 'none'
        },
        emailInput: {
            padding: '1em',
            width: '85%',
            '& label': {
                fontWeight: 'bold',
                padding: '1em 0 0 1em',
                // fontSize: '1.4em',
                color: 'rgba(0, 0, 0, 0.8) !important'
            },
            '&::before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderBottomStyle: 'solid'
            }
        },
        deleteButton: {
            width: '15%',
            color: '#eb445a'
        },
        warningIcon: {
            color: '#eb445a',
            marginTop: '0.5em'
        },
        placeholderCell: {
            color: 'gray',
            marginTop: '1em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount: AccountFull | null;
    accountTeams: Team[];
    onFormSubmit?: (gridRows: UserPayloadEntry[]) => void;
}

interface States {
    gridRows: ValidAgGridRowModel[];
    gridColumns: ColDef[];
    gripOptions: GridOptions;
    validClipboardRefData: ValidGridRefData;
    canSubmit: boolean;
    totalEntries: number;
    isLoadingGrid: boolean;
    isNotificationModalOpened: boolean;
    notificationModalHeader: string;
    notificationModalText: string;
}

class UserBulkCreationForm extends React.Component<Props, States> {
    processCellForClipboard = (params: ProcessCellForExportParams) => {
        const { accountTeams } = this.props;
        if (params.column.getColId() === 'team') {
            params.value = JSON.stringify(
                accountTeams.find(e => e.name === params.value)!
            );
        }
        return params.value;
    };

    state: States = {
        gridRows: [],
        gridColumns: [],
        gripOptions: {
            suppressCutToClipboard: true,
            processCellForClipboard: this.processCellForClipboard
        },
        validClipboardRefData: {},
        canSubmit: false,
        totalEntries: 10,
        isLoadingGrid: true,
        isNotificationModalOpened: false,
        notificationModalHeader: '',
        notificationModalText: ''
    };

    setInvalidField = (param: ValueSetterParams) => {
        const data = param.data;
        const field = param.colDef.field!;

        data[field] = {
            ...data[field],
            lastValidation: false,
            email: param.newValue
        };
        
        param.api.applyTransaction({ update: [data] });
        return true;
    };

    validateEmailEntry = (param: ValueSetterParams) => {
        const { currentAccount } = this.props;
        const data = param.data;
        const field = param.colDef.field;
        const newValue = param.newValue as string;

        if (!field) {
            return this.setInvalidField(param);
        }

        if (
            !currentAccount ||
            !currentAccount.email_domain_sso ||
            currentAccount.email_domain_sso.length <= 0
        ) {
            return this.setInvalidField(param);
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newValue)) {
            return this.setInvalidField(param);
        }

        const domain = newValue.split('@')[1];
        // Check if the domain is in the approved list
        if (currentAccount.email_domain_sso.includes(domain)) {
            data[field] = {
                ...data[field],
                lastValidation: true,
                email: param.newValue
            };
            param.api.applyTransaction({ update: [data] });
        }
        return true;
    };

    parseTeamData = (param: ValueParserParams) => {
        const { accountTeams } = this.props;
        try {
            const newTeam: Team = JSON.parse(param.newValue);
            if (accountTeams.find(e => e.team_id === newTeam.team_id)) {
                return newTeam;
            } else {
                return param.oldValue;
            }
        } catch (e) {
            return param.oldValue;
        }
    };

    parseStringData = (param: ValueParserParams) => {
        try {
            JSON.parse(param.newValue);
        } catch (e) {
            return param.newValue;
        }
        return param.oldValue;
    };

    deleteRowInGrid = (params: CustomCellRendererProps) => {
        let { totalEntries, gridRows } = this.state;
        totalEntries -= 1;
        params.api.applyTransaction({
            remove: [params.node!.data]
        }); 

        gridRows.splice(params.rowIndex , 1);

        this.setState({ isLoadingGrid: true }, () => {
            this.setState({ gridRows, totalEntries, isLoadingGrid: false });
        });
    };

    componentDidMount() {
        const emptygridRows = Array.from({ length: 10 }, (_, index) => ({
            ...PLACEHOLDDER_ITEM
        }));

        const { classes, accountTeams } = this.props;
        if (accountTeams.findIndex(e => e.team_id === EMPTY_TEAM.team_id) < 0) {
            accountTeams.push(EMPTY_TEAM);
        }
        const teamRefData: ValidGridRefData = {};
        accountTeams.forEach(e => {
            teamRefData[e.team_id] = e.name;
        });

        this.setState(
            {
                gridColumns: [
                    {
                        headerName: '',
                        field: 'actions',
                        cellRenderer: (params: CustomCellRendererProps) => (
                            <IconButton
                                className={classes.deleteButton}
                                aria-label="delete"
                                onClick={e => this.deleteRowInGrid(params)}
                            >
                                <DeleteRounded />
                            </IconButton>
                        ),
                        editable: false,
                        suppressHeaderMenuButton: true,
                        resizable: false,
                        maxWidth: 60
                    },
                    {
                        headerName: 'Email (*)',
                        field: 'email',
                        valueGetter: param => param.data['email'].email,
                        valueSetter: param => this.validateEmailEntry(param),
                        editable: true,
                        suppressHeaderMenuButton: true,
                        cellRenderer: (params: CustomCellRendererProps) =>
                            params.value || (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    className={classes.placeholderCell}
                                >
                                    Enter valid user email
                                </Typography>
                            ),
                        singleClickEdit: true,
                        useValueParserForImport: true,
                        valueParser: param => this.parseStringData(param)
                    },
                    {
                        colId: 'validation',
                        valueGetter: param => param.data['email'],
                        headerName: '',
                        cellRenderer: (params: CustomCellRendererProps) =>
                            params.value.lastValidation ? (
                                <CheckOutlined color="primary" />
                            ) : (
                                <ErrorOutline className={classes.warningIcon} />
                            ),
                        minWidth: 10,
                        maxWidth: 60,
                        suppressHeaderMenuButton: true
                    },
                    {
                        headerName: 'First Name',
                        field: 'fname',
                        editable: true,
                        suppressHeaderMenuButton: true,
                        singleClickEdit: true,
                        cellRenderer: (params: CustomCellRendererProps) =>
                            params.value || (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    className={classes.placeholderCell}
                                >
                                    Optional
                                </Typography>
                            ),
                        useValueParserForImport: true,
                        valueParser: param => this.parseStringData(param)
                    },
                    {
                        headerName: 'Last Name',
                        field: 'lname',
                        editable: true,
                        suppressHeaderMenuButton: true,
                        singleClickEdit: true,
                        cellRenderer: (params: CustomCellRendererProps) =>
                            params.value || (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    className={classes.placeholderCell}
                                >
                                    Optional
                                </Typography>
                            ),
                        useValueParserForImport: true,
                        valueParser: param => this.parseStringData(param)
                    },
                    {
                        headerName: 'Password (*)',
                        field: 'password',
                        editable: true,
                        suppressHeaderMenuButton: true,
                        singleClickEdit: true,
                        cellRenderer: (params: CustomCellRendererProps) =>
                            params.value || (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    className={classes.placeholderCell}
                                >
                                    User password
                                </Typography>
                            ),
                        useValueParserForImport: true,
                        valueParser: param => this.parseStringData(param)
                    },
                    {
                        headerName: 'Team (*)',
                        field: 'team',
                        editable: true,
                        cellEditor: 'agRichSelectCellEditor',
                        valueGetter: param => param.data['team'].name,
                        cellEditorParams: {
                            values: accountTeams,
                            formatValue: (t: Team) => t.name,
                            parseValue: (t: Team) => t.name,
                            searchType: 'matchAny',
                            allowTyping: true,
                            filterList: true
                        } as IRichCellEditorParams,
                        suppressHeaderMenuButton: true,
                        useValueParserForImport: true,
                        suppressFillHandle: true,
                        valueParser: param => this.parseTeamData(param)
                    }
                ],
                gridRows: emptygridRows
            },
            () => {
                this.setState({ isLoadingGrid: false });
            }
        );
    }

    totalgridRows = () => {
        const { gridRows } = this.state;

        return gridRows.length;
    };

    modifyFormCount = (isAdd: boolean) => {
        const { gridRows } = this.state;
        let { totalEntries } = this.state;

        if (isAdd) {
            totalEntries += 1;
            gridRows.push({ ...PLACEHOLDDER_ITEM });
        } else {
            totalEntries -= 1;
            gridRows.pop();
        }

        this.setState({ isLoadingGrid: true }, () => {
            this.setState({ gridRows, totalEntries, isLoadingGrid: false });
        });
    };

    handleStringChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
        index: number
    ) => {
        const { gridRows } = this.state;

        if (!gridRows) {
            return;
        }

        _.set(gridRows[index], key, e.target.value);
        this.setState({ gridRows });
    };

    // OnCSVParsed = (results: Papa.ParseResult<string[]>) => {
    //     const { data } = results;
    //     const { accountTeams } = this.props;
    //     const { gridRows } = this.state;

    //     // const checkEntry = data[0] as any;

    //     // if (!checkEntry.email)) {
    //     //     this.setState({
    //     //         isNotificationModalOpened: true,
    //     //         notificationModalHeader: 'CSV import error',
    //     //         notificationModalText: 'Invalid CSV, please check your file',
    //     //     });
    //     //     return;
    //     // }

    //     const gridRowsCSV: newUserFormField[] = data.map((e: any) => {
    //         const entry: newUserFormField = {
    //             email: e.email,
    //             isEmailValid: false,
    //             firstName: e.first_name ? e.first_name : '-',
    //             lastName: e.last_name ? e.last_name : '-',
    //             defaultTeam: accountTeams.find(j => j.team_id === e.team)!
    //         };

    //         return entry;
    //     });
    //     const filteredEntries = gridRows.filter(e => e.email !== '');
    //     const finalEntries = filteredEntries.concat(gridRowsCSV);

    //     this.setState({
    //         gridRows: finalEntries,
    //         totalEntries: finalEntries.length
    //     });
    // };

    // OnCsvTransformHeader = (header: string): string => {
    //     return header.replace(new RegExp(' ', 'g'), '_').toLocaleLowerCase();
    // };

    // OnImportFromCSVClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = event.target.files;
    //     if (!files) {
    //         return;
    //     }

    //     const csvFile = files[0];
    //     Papa.parse(csvFile, {
    //         complete: this.OnCSVParsed,
    //         header: true,
    //         transformHeader: this.OnCsvTransformHeader,
    //         skipEmptyLines: 'greedy'
    //     });
    // };

    onNotificationModalClicked = () => {
        this.setState({ isNotificationModalOpened: false });
    };

    checkSubmit = () => {
        const { gridRows } = this.state;
        const { currentAccount } = this.props;

        const checkList: boolean[] = [];
        gridRows.forEach((e: unknown, index) => {
            const item = e as newUserFormField;
            let emailCheck = false;
            if (item.email.email !== '' && item.email.lastValidation) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(item.email.email)) {
                    emailCheck = false;
                }

                const domain = item.email.email.split('@')[1];
                // Check if the domain is in the approved list
                if (!currentAccount!.email_domain_sso!.includes(domain)) {
                    emailCheck = false;
                }
                emailCheck = true;
            }

            const teamCheck = item.team.team_id !== EMPTY_TEAM.team_id;
            const passwordCheck = item.password !== '';
            checkList.push(emailCheck && teamCheck && passwordCheck);
        });
        return checkList.filter(e => e === false).length > 0;
    };

    onFormSubmit = () => {
        const { onFormSubmit } = this.props;

        if (!onFormSubmit) {
            return;
        }

        const { gridRows } = this.state;

        const formattedPayload = gridRows.map((e: unknown) => {
            const userRow = e as newUserFormField;
            return {
                email: userRow.email.email,
                first_name: userRow.fname,
                last_name: userRow.lname,
                password: userRow.password,
                team: userRow.team.team_id
            } as UserPayloadEntry;
        });
        onFormSubmit(formattedPayload);
    };

    validateClipboardData = (params: ProcessDataFromClipboardParams) => {
        const { data } = params;
        const { validClipboardRefData } = this.state;

        if (!validClipboardRefData) {
            return null;
        }

        return data;
    };

    directRowModify = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTotal = event.target.value as unknown as number;

        const { gridRows } = this.state;
        let { totalEntries } = this.state;

        const entryDiff = totalEntries - newTotal;
        let finalRows: ValidAgGridRowModel[] = [];

        if (entryDiff === 0) {
            this.setState({ totalEntries: newTotal });
            return;
        } else if (entryDiff < 0) {
            const newItems = Array.from({ length: Math.abs(entryDiff) }, (_, index) => ({
                ...PLACEHOLDDER_ITEM
            }));
            finalRows = gridRows.concat(newItems);
        } else {
            gridRows.splice(0, entryDiff);
            finalRows = gridRows;
        }

        this.setState({ isLoadingGrid: true, totalEntries: newTotal }, () => {
            this.setState({ gridRows: finalRows, isLoadingGrid: false });
        });
    };

    public render() {
        const { className, classes, currentAccount, accountTeams } = this.props;

        const {
            gridRows,
            gridColumns,
            gripOptions,
            totalEntries,
            isLoadingGrid,
            isNotificationModalOpened,
            notificationModalHeader,
            notificationModalText
        } = this.state;

        return (
            <div
                className={`${classes.root} ${className}`}
                data-testid="user-bulk-creation-form"
            >
                {currentAccount &&
                currentAccount.email_domain_sso &&
                currentAccount.email_domain_sso.length > 0 ? (
                    <div
                        className={`${classes.container} ${classes.stretchContainer}`}
                    >
                        <div className={classes.buttonContainer}>
                            <Typography
                                variant="h6"
                                className={`${classes.heading} ${classes.ellipsis} ${classes.buttonTitle}`}
                            >
                                User creation form for {currentAccount.name}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonExport}
                                disabled={this.checkSubmit()}
                                onClick={this.onFormSubmit}
                            >
                                Submit
                            </Button>
                            {/* Upload CSV button */}
                            {/* <input
                                id="csv-button"
                                data-testid="csv-button"
                                className={classes.input}
                                accept=".csv"
                                type="file"
                                onChange={this.OnImportFromCSVClicked}
                            />
                            <label htmlFor="csv-button">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    className={classes.buttonImport}
                                    startIcon={<PublishRounded />}
                                >
                                    Import from CSV
                                </Button>
                            </label> */}
                        </div>
                        <Paper className={classes.treeHeader}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography
                                        variant="subtitle1"
                                        component="h6"
                                    >
                                        <b>Valid email domains: </b>
                                        {currentAccount.email_domain_sso.join(
                                            ', '
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ButtonGroup
                                        color="primary"
                                        className={classes.formHeaderGroup}
                                    >
                                        <div className={classes.itemLabel}>
                                            Items in form
                                        </div>
                                        <Button
                                            color="primary"
                                            onClick={e =>
                                                this.modifyFormCount(true)
                                            }
                                        >
                                            <AddOutlined />
                                        </Button>
                                        <div>
                                            <TextField
                                                id="forms-item"
                                                type="number"
                                                className={classes.sizeInput}
                                                value={totalEntries}
                                                inputProps={{ min: 0 }}
                                                onChange={this.directRowModify}
                                            />
                                        </div>
                                        <Button
                                            color="secondary"
                                            onClick={e =>
                                                this.modifyFormCount(false)
                                            }
                                        >
                                            <RemoveOutlined />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.treePaper}>
                            <AgGridList
                                className={classes.formHolder}
                                isLoading={isLoadingGrid}
                                startAsEditableMode={true}
                                rows={gridRows}
                                columns={gridColumns}
                                gridOptions={gripOptions}
                                onGridChanged={this.checkSubmit}
                                rowSelection="multiple"
                                enableRangeSelection={true}
                                enableFillHandle={true}
                                suppressClearOnFillReduction={true}
                                validateClipboardData={
                                    this.validateClipboardData
                                }
                                excludeColumnFillIds={['team']}
                                pagination={true}
                                paginationPageSize={100}
                                paginationPageSizeSelector={[25, 50, 100]}
                                rowHeight={50}
                            />
                        </Paper>
                    </div>
                ) : (
                    <Paper
                        className={`${classes.container} ${
                            classes.simpleListTeams
                        } ${classes.teamSpinnerContainer} ${
                            !isLoadingGrid ? classes.noAccountContainer : ''
                        } ${classes.stretchContainer}`}
                    >
                        <CCSpinner loading={isLoadingGrid} size={70}>
                            <Typography
                                className={classes.centerText}
                                variant={!accountTeams ? 'h4' : 'h5'}
                            >
                                {currentAccount
                                    ? !currentAccount.email_domain_sso ||
                                      currentAccount.email_domain_sso.length <=
                                          0
                                        ? 'Account does not have valid IAM configuration'
                                        : !accountTeams &&
                                          'No teams for the selected account'
                                    : 'No organization selected'}
                            </Typography>
                        </CCSpinner>
                    </Paper>
                )}
                <SimpleDialog
                    open={isNotificationModalOpened}
                    titleText={notificationModalHeader}
                    contentText={notificationModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onNotificationModalClicked}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(UserBulkCreationForm);
export { MUIComponent as UserBulkCreationForm };
