import * as React from 'react';

import {
    Drawer,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';


export interface MenuDescriptor {
    label: string;
    icon?: React.ReactChild;
    data?: unknown;
    className?: string;
    disabled?: boolean;
}

export type menuDirection = 'right' | 'left';
export type manuVariant = 'permanent' | 'persistent' | 'temporary' | undefined;
export type TabControlColor = 'primary' | 'secondary' | undefined;

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0'
    },
    menuContentContainer: {
        flex: '1 0 0'
    },
    paper: {
        zIndex: 'auto'
    },
    menuItem: {
        padding: '0.5em 2.5em'
    }
});

interface Props extends WithStyles<typeof styles> {
    menuDescriptors: MenuDescriptor[];
    menuOpened: boolean;
    selectedMenu?: number;
    selectedMenuColor: TabControlColor;
    variant: manuVariant;
    menuDirection: menuDirection;
    children?: React.ReactNode;
    onMenuToggle?: () => void;
    onMenuChange?: (selectedMenuIndex: number, selectedMenu: MenuDescriptor) => void;
}

interface States {
    selectedMenu: number;
}

class HamburgerControl extends React.Component<Props, States> {
    public static defaultProps = {
        selectedMenuColor: 'primary' as TabControlColor
    };

    state = {
        selectedMenu: this.props.selectedMenu ? this.props.selectedMenu : 0
    };
 
    getEnabledMenu = memoize(
        (menuDescriptors: MenuDescriptor[]): number => {
            return menuDescriptors.findIndex((descriptor: MenuDescriptor) =>{
                return !descriptor.disabled;
            });
        });

    getMenuList = memoize((menuDescriptors: MenuDescriptor[], selectedMenu: number) => {
        const { selectedMenuColor, classes } = this.props;
        return menuDescriptors.map((descriptor: MenuDescriptor, index: number) => {
            const {
                label,
                icon,
                className,
                disabled
            } = descriptor;
            return (
                <ListItem
                    button
                    disabled={!!disabled}
                    key={`${index}-${label}`}
                    className={className ? className : classes.menuItem}
                    selected={index === selectedMenu ? true: false}
                    color={selectedMenuColor}
                    onClick={() => this.onMenuClicked(index)}
                >
                    {icon && icon}
                    <ListItemText primary={label} />
                </ListItem>
            )   
        });
    });

    onMenuClicked = (selectedMenu: number) => {
        const { selectedMenu: selectedTabState } = this.state;
        if (selectedMenu === selectedTabState) {
            return;
        }

        const { onMenuChange, onMenuToggle } = this.props;
        if (onMenuChange) {
            const { menuDescriptors } = this.props;

            onMenuChange(selectedMenu, menuDescriptors[selectedMenu]);

            if (onMenuToggle) {
                onMenuToggle();
            }
        }

        this.setState({ selectedMenu });
    };

    public render() {
        const {
            classes,
            children,
            selectedMenu: selectedTabProp,
            menuDescriptors,
            variant,
            menuDirection,
            menuOpened,
            onMenuToggle
        } = this.props;
        const {
            selectedMenu: selectedTabState
        } = this.state;


        const container = window !== undefined ? () => window.document.body : undefined;

        const currentSelectedTab = selectedTabProp !== undefined ? selectedTabProp : selectedTabState;
        const selectedMenu =
            menuDescriptors ?
                (!menuDescriptors[currentSelectedTab].disabled) ?
                    currentSelectedTab
                    : this.getEnabledMenu(menuDescriptors)
                : -1;
        const currentMenuContent =
            children ? 
                children instanceof Array ?
                    children[selectedMenu]
                    : children
                : '';
        
        return (
            <div className={classes.root}>
                <Drawer
                    container={container}
                    variant={variant}
                    anchor={menuDirection}
                    open={menuOpened}
                    onClose={onMenuToggle}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    {this.getMenuList(menuDescriptors, selectedMenu)}
                </Drawer>
                <div className={classes.menuContentContainer}>
                    {
                        currentMenuContent ? currentMenuContent : ''
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(HamburgerControl);
export {MUIComponent as HamburgerControl};
