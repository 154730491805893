import { Button, createStyles, Divider, FormControlLabel, Grid, ListItemText, Paper, Switch, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";
import { Account, Team } from "../../shared/domain";
import * as React from 'react';
import _ from "lodash";
import { CCSpinner } from "../../shared/components/cc-spinner";
import { FolderFull } from "../../models/folder";
import { LocationInput } from "../location-input";
import { FolderPositionPicker, StoredTreeSelection } from "../folder-position-picker/folder-position-picker";
import { EmailDomainForm } from "../email-domain-form/email-domain-form";
// import { DeleteForeverRounded } from "@material-ui/icons";


export type FolderFormInput = 'string' | 'location' | 'emails' | 'position' | 'boolean';
export interface folderForm {
    field: string;
    required: boolean;
    input: FolderFormInput;
    items?: string[];
}

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        simpleList: {
            minWidth: '30em'
        },
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsisDetails: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'visible'
    },
    simpleList: {
        height: '100%',
        minWidth: '30em',
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    listHeader: {
        paddingLeft: '1em',
        '& span': {
            fontWeight: 'bolder'
        }
    },
    dividerForm: {
        margin: '1em'
    },
    detailBorder: {
        border: '1px solid #e1e1e1',
        overflowY: 'scroll',
        height: '100%'
    },
    processingSpinner: {
        position: 'absolute',
        width: '35em',
        height: '100%',
        background: 'rgba(113, 113, 113, 0.47)',
        zIndex: 9
    },
    saveButton: {
        marginTop: '1em',
        marginBottom: '1em'
    },
    toggleItem: {
        '& .MuiSwitch-root': {
            marginRight: '1em'
        },
        '& .MuiFormControlLabel-label': {
            width: '100%',
            fontWeight: 'bolder',
        }
    },
    "@global": {
        '@keyframes fadeIn': {
            '0%': { opacity: 0 },
            '100%': { opacity: 1 }
        },
    },
    deleteButton: {
        color: '#eb445a',
        animation: 'fadeIn 1s'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount?: Account | null;
    pickedFolder: FolderFull | undefined;
    formFields: folderForm[];
    hiddenAttributes: string[];
    isProcessing: boolean;
    isCreating: boolean;
    canDelete: boolean;
    teams?: Team[];
    storedFolderSelection?: StoredTreeSelection;
    onFormSubmit?: (updatedFolder: FolderFull, storedFolderSelection?: StoredTreeSelection) => void;
    onFormChanges?: () => void;
    onFormDelete?: (pickedFolder: FolderFull) => void;
}

interface States {
    folderInForm: FolderFull;
    formList: React.ReactChild[];
    canSubmit: boolean;
    isManager: boolean;
    isPositionSet: boolean;
    excludedEmailDomains: string[];
    storedFolderSelection?: StoredTreeSelection;
}

class FolderForm extends React.Component<Props, States> {

    state: States = {
        folderInForm: {} as FolderFull,
        formList: [],
        canSubmit: false,
        isManager: false,
        isPositionSet: false,
        excludedEmailDomains: [],
        storedFolderSelection: undefined
    }

    componentDidMount() {
        const { pickedFolder, storedFolderSelection } = this.props;
        if (!pickedFolder) {
            return;
        }
        const folderInForm = {...pickedFolder};
        // ENABLE 'email_domains' FOR ALL FOLDERS
        if (!folderInForm.email_domains) {
            folderInForm.email_domains = {};
        }

        const excludedEmailDomains = `${process.env.REACT_APP_EXCLUDED_DOMAIN_LIST}`

        this.setState({
            folderInForm,
            excludedEmailDomains: excludedEmailDomains.split(','),
            storedFolderSelection
        }, () => {
            this.formatFolderForm();
        });
    }

    handleStringChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const { folderInForm } = this.state;
        const { onFormChanges } = this.props;

        if (!folderInForm) {
            return;
        }

        if (!onFormChanges) {
            return;
        }

        _.set(folderInForm, key, e.target.value);
        this.setState({folderInForm, canSubmit: true}, () => {
            onFormChanges();
        });
    }

    handleLocationChange = (key: string, newLocation: FolderFull['location']) => {
        const { folderInForm } = this.state;

        const { onFormChanges } = this.props;

        if (!folderInForm) {
            return;
        }

        if (!onFormChanges) {
            return;
        }

        _.set(folderInForm, key, newLocation);
        this.setState({folderInForm, canSubmit: true}, () => {
            onFormChanges();
        });
    }

    handlePositionChange = (folderId: string, storedFolderSelection?: StoredTreeSelection) => {
        const { folderInForm } = this.state;

        const { onFormChanges } = this.props;

        if (!folderInForm) {
            return;
        }

        if (!onFormChanges) {
            return;
        }

        _.set(folderInForm, 'position', folderId);
        this.setState({folderInForm, canSubmit: true, isPositionSet: true, storedFolderSelection}, () => {
            onFormChanges();
        });
    }

    handleEmailDomainChange = (key: string, newEmailDomains: FolderFull['email_domains']) => {
        const { folderInForm } = this.state;
        const { onFormChanges } = this.props;

        if (!folderInForm) {
            return;
        }

        if (!onFormChanges) {
            return;
        }

        _.set(folderInForm, key, newEmailDomains);
        this.setState({folderInForm, canSubmit: true}, () => {
            onFormChanges();
        });
    }

    handleBooleanChange = (key: string) => {
        const { folderInForm } = this.state;
        const { onFormChanges } = this.props;

        if (!folderInForm) {
            return;
        }

        if (!onFormChanges) {
            return;
        }
        
        const oldValue = _.get(folderInForm, key);
        _.set(folderInForm, key, !oldValue as boolean);
        this.setState({folderInForm, canSubmit: true}, () => {
            onFormChanges();
        });
    }

    formatFieldName = (teamKey: string) => {
        const lowerWords = teamKey.split("_").join(" ");
        return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    formatFolderForm = () => {
        const { formFields, classes, hiddenAttributes, currentAccount, teams, isCreating } = this.props;
        const { folderInForm, excludedEmailDomains, storedFolderSelection } = this.state;
        const formattedFormFields: React.ReactChild[] = [];
        for (const key in folderInForm) {
            if (hiddenAttributes.includes(key)) {
                continue;
            }
            const keyValue = folderInForm[key as keyof FolderFull];
            const keyName = this.formatFieldName(key);
            // CHECK IF THE FIELD IS EDITABLE OR NOT
            const formType = formFields.find(e => e.field === key);
            if (formType) {
                // IS EDITABLE
                // CHECK TYPE
                switch (formType.input) {
                    case 'string':
                        formattedFormFields.push(
                            <TextField
                                className={classes.textField}
                                id={`${folderInForm.folder_id}_${key}`}
                                label={`${keyName} ${isCreating && formType.required ? '* ' : ''}`}
                                defaultValue={keyValue}
                                onChange={e => this.handleStringChange(e, key)}
                                multiline={true}
                                placeholder={keyName}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        );
                        break;
                    case 'location':
                        formattedFormFields.push(<ListItemText className={classes.listHeader} primary={`${keyName} ${isCreating && formType.required ? '* ' : ''}`} />);
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <LocationInput
                                        locationCurrentValue={keyValue as FolderFull['location']}
                                        locationKey={key}
                                        canEdit={true}
                                        onLocationChange={this.handleLocationChange}
                                    />
                                </Grid>
                            </Grid>,
                            <Divider className={classes.dividerForm} />
                        );
                        break;
                    case 'position':
                        formattedFormFields.push(<ListItemText className={classes.listHeader} primary={`Select the folder position ${isCreating && formType.required ? '* ' : ''}`} />);
                        formattedFormFields.push(
                            <FolderPositionPicker
                                currentAccount={currentAccount!}
                                onFolderPick={this.handlePositionChange}
                                previousSelection={storedFolderSelection}
                            />
                        );
                        break;
                    case 'emails':
                        formattedFormFields.push(<ListItemText className={classes.listHeader} primary={`Email domains ${isCreating && formType.required ? '* ' : ''}`}/>);
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <EmailDomainForm
                                        currentAccount={currentAccount!}
                                        existingEmailDList={keyValue as FolderFull['email_domains']}
                                        teams={teams!}
                                        emailDKey={key}
                                        canEdit={true}
                                        onEmailDomainChange={this.handleEmailDomainChange}
                                        excludedEmailDomains={excludedEmailDomains}
                                    />
                                </Grid>
                            </Grid>
                        );
                        break;
                    case 'boolean':
                        formattedFormFields.push(
                            <FormControlLabel
                                className={classes.toggleItem}
                                id={`${folderInForm.folder_id}_${key}`}
                                control={
                                    <Switch
                                        color="primary"
                                        defaultChecked={keyValue as boolean}
                                        onChange={() => this.handleBooleanChange(key)}
                                    />
                                }
                                label={keyName}
                                labelPlacement="start"
                                data-testid={`toggle_${key}`}
                            />
                        );
                        break;
                    default:
                        break;
                }
            } else {
                // NOT EDITABLE
                if (typeof keyValue === 'string' || typeof keyValue === 'number') {
                    if (keyValue !== '') {
                        formattedFormFields.push(
                            <TextField
                                className={classes.textField}
                                id={`${folderInForm.folder_id}_${key}`}
                                label={keyName}
                                defaultValue={keyValue !== '' ? keyValue : '-'}
                                disabled={true}
                                multiline={true}
                            />
                        );
                    }
                } else if (typeof keyValue === 'object') {
                    formattedFormFields.push(<ListItemText className={classes.listHeader} primary={keyName} />);
                    switch (key) {
                        case 'location':
                            formattedFormFields.push(<ListItemText className={classes.listHeader} primary={keyName} />);
                            formattedFormFields.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <LocationInput
                                            locationCurrentValue={keyValue as FolderFull['location']}
                                            locationKey={key}
                                            canEdit={false}
                                        />
                                    </Grid>
                                </Grid>,
                                <Divider className={classes.dividerForm} />
                            );
                        break;
                        default:
                            break;
                    }
                }
            }
        }

        this.setState({formList: formattedFormFields});
    }

    onSubmitChanges = () => {
        const { onFormSubmit } = this.props;
        const { folderInForm, storedFolderSelection } = this.state;

        if (!onFormSubmit || this.checkValidForm()) {
            return;
        }

        onFormSubmit(folderInForm, storedFolderSelection);
    }

    checkValidForm = () => {
        const { isCreating, formFields } = this.props;
        
        const { folderInForm, isPositionSet, excludedEmailDomains } = this.state;
        let flags = [];
        for (const item of formFields) {
            if (item.required) {
                switch (item.input) {
                    case 'position':
                        if (!isCreating) {
                            flags.push(false);
                            break;
                        }
                        flags.push(!isPositionSet);
                        break;
                    case 'string':
                        flags.push(folderInForm[item.field as keyof FolderFull] === '');
                        break;
                    case 'location':
                        const currentLocal = folderInForm[item.field as keyof FolderFull]! as FolderFull['location'];
                        if (isNaN(currentLocal!.coordinates[0]) || isNaN(currentLocal!.coordinates[1])) {
                            flags.push(true);
                            break;
                        }
                        flags.push(false);
                        break;
                    case 'emails':
                        for (const email in folderInForm.email_domains!) {
                            const match = email.match(/@(.+)/);
                            flags.push(match && excludedEmailDomains.includes(match[1]) ? true : false);
                        }
                }
            }
        }
        return flags.includes(true);
    }

    onDeleteClicked = () => {
        const { canDelete, onFormDelete } = this.props;

        if (!canDelete || !onFormDelete) {
            return;
        }

        const { folderInForm } = this.state;

        onFormDelete(folderInForm);
    }

    public render() {
        const {
            className,
            classes,
            pickedFolder,
            isProcessing,
            isCreating,
            // canDelete
        } = this.props;
        const {
            formList,
            canSubmit,
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="folder-form">
                <div className={`${classes.container} ${classes.simpleList}`}>
                    <CCSpinner
                        loading={isProcessing}
                        size={70}
                        className={classes.processingSpinner}
                    />
                    <Typography
                        variant='h6'
                        className={`${classes.heading}`}
                    >
                        {/* Disabled until row id changes are live */}
                        {/* {
                            canDelete &&
                                <IconButton
                                    className={classes.deleteButton}
                                    onClick={this.onDeleteClicked}
                                    data-testid="delete-folder-button"
                                >
                                    <DeleteForeverRounded fontSize="large"/>
                                </IconButton>
                        } */}
                        { isCreating ? 'Creating new folder' : `Editing: ${pickedFolder!.name}` }
                    </Typography>
                    { canSubmit && 
                        <Button
                            className={classes.saveButton}
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmitChanges}
                            data-testid="submit-form-button"
                            disabled={this.checkValidForm()}
                        >
                            { isCreating ? 'Create Folder' : 'Save Changes'}
                        </Button>
                    }
                    <Paper className={`${classes.container} ${classes.detailBorder}`}>
                        { formList }
                    </Paper>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FolderForm);
export {MUIComponent as FolderForm};
