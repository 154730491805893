import * as React from 'react';

import {
    IconButton,
    Slide,
    Snackbar,
    SnackbarContent
} from '@material-ui/core';
import {
    SnackbarOrigin,
    SnackbarProps
} from '@material-ui/core/Snackbar';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

export type CCSnackbarVariant = 'success' | 'warning' | 'error' | 'info';
export type CCSnackbarTransition = 'bottom' | 'left' | 'right' | 'top';

const styles = (theme: Theme) => createStyles({
    root: {
        '&.cc-snackbar-closed':{
            display: 'none'
        }
    },
    success: {
        backgroundColor: theme.ccPalette.cc_colors.solid.ccCentersilhouette.main,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main,
    },
    warning: {
        backgroundColor: theme.ccPalette.warning.main,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '32.8em',
        textAlign: 'justify'
    },
    content: {},
    close: {}
});

interface Props extends WithStyles<typeof styles> {
    showCloseButton?: boolean;
    variant: CCSnackbarVariant;
    transition: CCSnackbarTransition;
    onClose?: () => void;
}


class CCSnackbar extends React.Component<Props & SnackbarProps> {
    public static defaultProps = {
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' } as SnackbarOrigin,
        transition: 'top' as CCSnackbarTransition
    };

    state = {
    };
    
    getTransition = 
        (transition: CCSnackbarTransition) => (props: unknown) => {
            let slideDirection: 'left' | 'right' | 'up' | 'down' | undefined;
            switch(transition) {
            case 'top':
                slideDirection='down';
                break;
            case 'bottom':
                slideDirection='up';
                break;
            case 'left':
                slideDirection='right';
                break;
            case 'right':
                slideDirection='left';
                break;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            // @ts-ignore
            return slideDirection ? (<Slide {...props} direction={slideDirection} />) as any : undefined;
        }

    public render() {
        const {
            classes,
            className,
            message,
            open,
            showCloseButton,
            transition,
            variant,
            onClose,
            ...other
        } = this.props;
        const Icon = variantIcon[variant];
        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}${open ? '' : ' cc-snackbar-closed'}`;
        
        return (
            <Snackbar
                className={rootClasses}
                TransitionComponent={open ? this.getTransition(transition) : undefined}
                open={open}
                onClose={onClose}
                {...other}
            >
                <SnackbarContent
                    className={`${classes[variant]} ${classes.content}`}
                    aria-describedby="cc-snackbar"
                    message={
                        <span
                            id="cc-snackbar"
                            className={classes.message}
                        >
                            <Icon className={`${classes.icon} ${classes.iconVariant}`} />
                            {message}
                        </span>
                    }
                    action={
                        showCloseButton ? [
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={onClose}
                            >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ] : undefined
                    }
                />
            </Snackbar>
        );
    }
}

const MUIComponent = withStyles(styles)(CCSnackbar);
export { MUIComponent as CCSnackbar };
