import { EntityUser, UserAnalyticsTab } from './../shared/domain/user.entity';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';
import { User } from '../shared/domain';

export interface UserIdentifiers {
    account_id: string;
    team_id: string;
    user_id: string;
    auth_token: string;
    expiry: number;
}

export interface UserPayloadEntry {
    email: string;
    first_name: string;
    last_name: string;
    team: string;
    password: string;
}

export interface BulkUserPayload {
    account_id: string;
    user_entries: UserPayloadEntry[];
}

export default class UserProvider {
    private endpointCore: string;
    private endpointAdmin: string;

    constructor() {
        this.endpointCore = `${process.env.REACT_APP_BASE_API_URL}users/`;
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}user/`;
    }

    init = () => {
        return HttpConnectorAdapter;
    };

    getUserIdentifiers = (userEmail: string) => {
        return this.init()
            .getWithCredentials(`${this.endpointCore}email/${userEmail}`)
            .then(response => {
                const { users } = response.data.results;
                const { user_id, teams } = users[0];

                return { user_id, teams };
            });
    };

    fetchUser = (userEmail: string) => {
        return this.init()
            .getWithCredentials(`${this.endpointCore}email/${userEmail}`)
            .then(response => {
                const { results } = response.data;
                const users: EntityUser[] = results.users as EntityUser[];
                return users.map((user: EntityUser) => {
                    return User.create(user);
                });
            });
    };

    fetchUserById = (userId: string) => {
        return this.init()
            .getWithCredentials(`${this.endpointAdmin}${userId}`)
            .then(response => {
                const user = response.data as User;
                const statusCode = response.status;

                return { status: statusCode, user };
            });
    };

    updateUserTabs = (userId: string, userTabs: UserAnalyticsTab[]) => {
        const payload = { tabs: userTabs };
        const headers = { 'Content-Type': 'application/json' };
        return this.init()
            .putWithCredentials(
                `${this.endpointAdmin}${userId}/tab`,
                payload,
                headers
            )
            .then(response => {
                const { valid } = response.data;

                if (!valid) {
                    throw {
                        error: "Error updating user's tabs",
                        errorMessage: 'Server returned empty response.'
                    };
                }

                return valid;
            });
    };

    addUserToTeam = (userId: string, teamId: string) => {
        return this.init()
            .putWithCredentials(`${this.endpointCore}${userId}/teams/${teamId}`)
            .then(response => {
                const { results } = response.data;
                const user: EntityUser = results.user as EntityUser;
                return User.create(user);
            });
    };

    removeUserFromTeam = (userId: string, teamId: string) => {
        return this.init()
            .deleteWithCredentials(
                `${this.endpointCore}${userId}/teams/${teamId}`
            )
            .then(response => {
                const { results } = response.data;
                const user: EntityUser = results.user as EntityUser;
                return User.create(user);
            });
    };

}
                    