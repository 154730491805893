import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RootStore } from '../../stores/root.store';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import AccountProvider from '../../providers/account.provider';
import TeamProvider from '../../providers/team.provider';
import UserProvider from '../../providers/user.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import {
    EmailSearch,
    EmailSearchResults
} from '../../components/email-search';
import {
    Account,
    Team,
    User
} from '../../shared/domain';
import MagicURLProvider, { MagicUrlForm } from '../../providers/magicurl.provider';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Error } from '@material-ui/icons';
import { SimpleDialog } from '../../shared/components/simple-dialog';

const ACCOUNTS_PAGING_LIMIT = 20;
const SIMPLE_LIST_MAX_HEIGHT = 210;

const styles = (theme: Theme) =>
    createStyles({
        [theme.breakpoints.down('lg')]: {
            formContainer: {
                width: '35% !important'
            },
            accountsContainer: {
                flex: '0.35 !important',
            },
            userDataContainer: {
                flex: '0.35 !important'
            },
            divider: {
                margin: '1em 0 !important'
            },
        },
        container: {
            marginTop: '1.5em',
            display: 'flex',
            flex: '1',
            flexDirection: 'row'
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em'
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        divider: {
            margin: '2em 0' 
        },
        accountsContainer: {
            flex: '0.35',
            marginLeft: '1.5em'
        },
        userDataContainer: {
            flex: '0.35'
        },
        mainSpinnerLabel: {
            paddingBottom: '30px'
        },
        progressContainer: {
            flexGrow: 1,
            height: '100%'
        },
        searchContainer: {
            height: SIMPLE_LIST_MAX_HEIGHT
        },
        searchResultsContainer: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        searchResultSimpleList: {
            height: '20em'
        },
        errorPopup: {
            width: '98%',
            borderRadius: 5
        },
        errorPopupHeader: {
            height: '0.6em',
            backgroundColor: theme.palette.error.main,
            borderRadius: '3px 3px 0 0'
        },
        errorPopupContentContainer :{
            display: 'flex',
            flexDirection: 'row',
            padding: theme.spacing(1),
        },
        errorPopupIcon: {
            fontSize: 40,
            marginRight: '1em'
        },
        detailBorder: {
            border: '1px solid #e1e1e1',
            overflowY: 'scroll',
        },
        textField: {
            padding: '1em',
            width: '100%',
            '& label': {
                fontWeight: 'bolder',
                padding: '1em 0 0 1em',
    
                fontSize: '1.4em',
                color: 'rgba(0, 0, 0, 0.8) !important'
            },
            '&::before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderBottomStyle: 'solid'
            }
        },
        listHeader: {
            paddingLeft: '1em',
            '& span': {
                fontWeight: 'bolder'
            }
        },
        dividerForm: {
            margin: '1em'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        saveButton: {
            margin: '1em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    currentEmail: string;
    currentTeam: Team | null;
    currentTeamOffset: number;
    searchCurrentTeam: Team | null;
    currentAccount: Account | null;
    currentAccountOffset: number;
    totalAccounts: number;
    mainSpinnerText: string;
    dataPageIsLoading: boolean;
    searchIsLoading: boolean;
    networkError: boolean;
    isNotificationModalOpened: boolean;
    notificationModalHeader: string;
    notificationModalText: string;
    isAccountsLoading: boolean;
    accounts: Account[];
    users: User[];
    searchResultTeams: Team[];
    searchResultAccounts: Account[];
    magicURLForm: MagicUrlForm;
}

@inject('rootStore')
@observer
class MagicURL extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {};

    state: States = {
        currentEmail: '',
        currentTeam: null,
        currentTeamOffset: 0,
        searchCurrentTeam: null,
        currentAccount: null,
        currentAccountOffset: 0,
        totalAccounts: 0,
        mainSpinnerText: '',
        dataPageIsLoading: false,
        searchIsLoading: false,
        networkError: false,
        isNotificationModalOpened: false,
        notificationModalHeader: '',
        notificationModalText: '',
        isAccountsLoading: false,
        accounts: [],
        users: [],
        searchResultTeams: [],
        searchResultAccounts: [],
        magicURLForm: {} as MagicUrlForm,
    };

    accountProvider = new AccountProvider();
    teamProvider = new TeamProvider();
    userProvider = new UserProvider();
    magicURLProvider = new MagicURLProvider();
        
    componentDidMount() {
        // const { onAuthError } = this.props;
        // if (onAuthError) {
        //     onAuthError();
        // }
        this.fetchAccounts(this.state.currentAccountOffset);
    };
    
    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                dataPageIsLoading: false,
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onSearchClicked = (email: string) => {
        this.loadUserDetails(email);
    };

    loadUserDetails = (email: string): Promise<User> => {
        const {
            rootStore: { adminStore }
        } = this.props;
        return new Promise((resolve, reject) => {
            this.setState({
                searchIsLoading: true
            });
            this.userProvider.fetchUser(email.toLocaleLowerCase())
                .then((users: User[]) => {
                    this.UpdateUsers(users).then(() => {
                        if (!users || (users.length !== 1)) {
                            this.setState({ searchIsLoading: false });
                            return reject();
                        }

                        this.setState({
                            searchIsLoading: false
                        });

                        const user = users[0];
                        return resolve(user);
                    });
                }).catch(error => {
                    this.setState({
                        searchIsLoading: false
                    });
                    adminStore.loadingError = `${error.error} - ${error.errorMessage}`;
                    return reject(error);
                });
        });
    }

    UpdateUsers = (users: User[]): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            // Fetch the user teams only if the user record
            // is valid (returned one user)
            if (users.length === 1) {
                const {
                    teams,
                    email
                } = users[0];

                const isEmailInUserPresent = !!email;

                // Check if the email is present in the user record
                if (!isEmailInUserPresent) {
                    // If it is not we add a fake user so
                    // EmailSearResults component will show an error message.
                    users.push(new User(0, true, [], true, 0));
                }

                if (isEmailInUserPresent && teams && (teams.length > 0)) {
                    const requests = [];
                    for (const team of teams) {
                        requests.push(this.teamProvider.fetchTeamFromTeamId(team.teamId))
                    }

                    Promise.all(requests).then((searchResultTeams: Team[]) => {
                        const userAccountIds: string[] = [];
                        for (const team of searchResultTeams) {
                            const accounts = team?.accounts;
                            if (accounts) {
                                for (const account of accounts) {
                                    if (userAccountIds.indexOf(account) < 0) {
                                        userAccountIds.push(account);
                                    }
                                }
                            }
                        }
                        this.getUserAccounts(userAccountIds)
                            .then((searchResultAccounts) => {
                                this.setState({
                                    currentEmail: email ? email : '',
                                    users,
                                    searchResultTeams,
                                    searchResultAccounts
                                });
                                resolve(true);
                            }).catch((error) => {
                                const { status } = error.response;
                                if (status === 401) {
                                    const { onAuthError } = this.props;
                                    if (!onAuthError) {
                                        return;
                                    }
                                    onAuthError();
                                    return;
                                }
                                this.setState({ networkError: true });
                                reject(error);
                            });
                    }).catch(error => {
                        const { status } = error.response;
                        if (status === 401) {
                            const { onAuthError } = this.props;
                            if (!onAuthError) {
                                return;
                            }
                            onAuthError();
                            return;
                        }
                        this.setState({ networkError: true });
                        reject(error);
                    });
                } else { // this account has no teams
                    this.setState({
                        currentEmail: email ? email : '',
                        users,
                        searchResultTeams: [],
                        searchResultAccounts: []
                    });

                    resolve(true);
                }
            } else {
                this.setState({
                    currentEmail: '',
                    users
                });

                resolve(false);
            }
        });
    };

    getUserAccounts = (accountIds: string[]): Promise<Account[]> => {
        return new Promise((resolve, reject) => {
            const requests = [] as Array<Promise<Account>>;
            const accountFetched: string[] = [];
            accountIds.forEach(accountId => {
                if (accountFetched.indexOf(accountId) < 0) {
                    accountFetched.push(accountId);
                    requests.push(
                        this.accountProvider.fetchAccount(accountId)
                    );
                }
            });
            Promise.all(requests)
                .then((userAccounts: Account[]) => {
                    return resolve(userAccounts);
                }).catch(() => {
                    reject();
                });
        });
    };

    onTeamClicked = (teamId: string) => {
        const { searchResultTeams, searchResultAccounts, users } = this.state;
        if (searchResultTeams.length <= 0 || users.length <= 0) {
            this.setState({
                currentTeam: null,
                currentAccount: null
            });
            return;
        }
        const selectedTeamIndex = searchResultTeams.findIndex(e => e.team_id === teamId);

        if (selectedTeamIndex < 0) {
            this.setState({
                currentTeam: null,
                currentAccount: null
            });
            return;
        }

        const selectedTeam = searchResultTeams[selectedTeamIndex];
        const selectedAccount = searchResultAccounts[selectedTeamIndex];

        this.setState({
            currentTeam: selectedTeam,
            currentAccount: selectedAccount,
            magicURLForm: {
                user_id: users[0].userId!,
                account_id: selectedAccount.accountId,
                team_id: selectedTeam.team_id,
                ttl: 7200
            }
        });
    };

    handleStringChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { magicURLForm } = this.state;

        if (!magicURLForm) {
            return;
        }

        magicURLForm.ttl = parseInt(e.target.value);
    };

    checkSubmitButton = () => {
        const { magicURLForm } = this.state;

        if (!magicURLForm) {
            return true;
        }

        return isNaN(magicURLForm.ttl);
    };

    onSubmitChanges = () => {
        const { magicURLForm } = this.state;

        if (!magicURLForm) {
            return;
        }
        this.setState({
            dataPageIsLoading: true,
            mainSpinnerText: 'Creating Magic URL'
        });

        this.magicURLProvider.createMagicUrl(magicURLForm).then((data) =>{
            this.setState({
                currentAccount: null,
                currentTeam: null,
                magicURLForm: {} as MagicUrlForm,
                dataPageIsLoading: false,
                isNotificationModalOpened: true,
                notificationModalHeader: 'Magic link creatd succesfully',
                notificationModalText: `Here is your newly created Magic URl: ${data.url}`
            })
        }).catch(error => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                dataPageIsLoading: false,
                isNotificationModalOpened: true,
                notificationModalHeader: `Error creating magic url: ${status}`,
                notificationModalText: error.stack_trace
            });
        });
    };

    onNotificationModalClicked = () => {
        this.setState({ isNotificationModalOpened: false });
    };

    public render() {
        const { classes } = this.props;
        const {
            mainSpinnerText,
            dataPageIsLoading,
            currentEmail,
            searchIsLoading,
            users,
            searchResultTeams,
            searchCurrentTeam,
            currentTeam,
            currentAccount,
            magicURLForm,
            networkError,
            isNotificationModalOpened,
            notificationModalHeader,
            notificationModalText
        } = this.state;

        const isSearchResultNotEmpty = !!users && (users.length > 0);
        const searchCurrentTeamId = searchCurrentTeam?.team_id;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label ={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <div className={classes.userDataContainer}>
                        <Typography
                            variant='h6'
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            Search for a user
                        </Typography>
                        <EmailSearch
                            email={currentEmail}
                            onSearchClick={this.onSearchClicked}
                        />
                        <Divider className={classes.divider} />
                        <CCSpinner
                            className={classes.searchContainer}
                            loading={searchIsLoading}
                            size={100}
                        >
                            <div className={classes.searchResultsContainer}>
                                <EmailSearchResults
                                    classes={{ list: classes.searchResultSimpleList }}
                                    searchResultTerm={currentEmail}
                                    users={isSearchResultNotEmpty ? users : []}
                                    teams={searchResultTeams}
                                    currentTeamId={searchCurrentTeamId}
                                    disabled={false}
                                    skipUserData={true}
                                    onClick={this.onTeamClicked}
                                />
                            </div>
                        </CCSpinner>
                    </div>
                    {
                        currentTeam && currentAccount &&
                        <div className={classes.accountsContainer}>
                            <Typography
                                variant='h6'
                                className={`${classes.heading}`}
                            >
                                Magic URL form:
                            </Typography>
                            <Paper className={`${classes.formContainer} ${classes.detailBorder}`}>
                                <TextField
                                    className={classes.textField}
                                    id="magic_url_selected_account"
                                    label="Selected Account"
                                    defaultValue={currentAccount.name}
                                    disabled={true}
                                    multiline={true}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="magic_url_selected_team"
                                    label="Selected Team"
                                    defaultValue={currentTeam.name}
                                    disabled={true}
                                    multiline={true}
                                />
                                <TextField
                                    className={classes.textField}
                                    id="magic_url_ttl"
                                    label="Time to live (in seconds)"
                                    defaultValue={magicURLForm.ttl}
                                    onChange={e => this.handleStringChange(e)}
                                    multiline={false}
                                    placeholder="7200"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Button
                                    className={classes.saveButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onSubmitChanges}
                                    data-testid="submit-form-button"
                                    disabled={this.checkSubmitButton()}
                                >
                                    Create Magic URL
                                </Button>
                            </Paper>
                        </div>
                    }
                </CCSpinner>
                {
                    networkError ?
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header = ' '
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                    :
                        <SimpleDialog
                            open={isNotificationModalOpened}
                            titleText={notificationModalHeader}
                            contentText={notificationModalText}
                            buttonCancelLabel=""
                            onDialogResult={this.onNotificationModalClicked}
                        />
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(MagicURL);
export { MUIComponent as MagicURL };
