import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';
import { AccountFull } from '../../models/account';
import AccountProvider from '../../providers/account.provider';
import JobProvider, {
    JobRequest,
    JobTypes,
    RunningTasks,
    STATIC_PROGRESS_LIMIT,
    StatusTypes
} from '../../providers/job.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import {
    AccountPicker,
    RunningTasksList,
    UserBulkCreationForm
} from '../../components';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Error } from '@material-ui/icons';
import { Button, Chip, Drawer, Typography } from '@material-ui/core';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { Team } from '../../shared/domain';
import UserProvider, {
    BulkUserPayload,
    UserPayloadEntry
} from '../../providers/user.provider';

const ROWS_PER_PAGE = 10;
const ACCOUNTS_PAGING_LIMIT = 20;

interface JobResultDataset {
    status: boolean;
    url: string;
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row'
        },
        analyticsTabList: {
            height: '18.2em'
        },
        errorPopup: {
            width: '98%',
            borderRadius: 5
        },
        errorPopupHeader: {
            height: '0.6em',
            backgroundColor: theme.palette.error.main,
            borderRadius: '3px 3px 0 0'
        },
        errorPopupContentContainer: {
            display: 'flex',
            flexDirection: 'row',
            padding: theme.spacing(1)
        },
        errorPopupIcon: {
            fontSize: 40,
            marginRight: '1em'
        },
        mainSpinnerLabel: {
            paddingBottom: '30px'
        },
        progressContainer: {
            flexGrow: 1
        },
        animContainer: {
            transition: 'width 1s'
        },
        listIcon: {
            marginRight: '0.5em',
            fontSize: '1em'
        },
        formHolder: {
            flex: 1
        },
        formContainer: {
            width: '30em'
        },
        jobMenuButton: {
            position: 'fixed',
            right: '6%',
            top: '3%'
        },
        notFinishedBadge: {
            marginLeft: '1em',
            background: '#ffb300',
            color: 'white',
            fontWeight: 'bolder'
        },
        finishedBadge: {
            marginLeft: '1em',
            background: '#107d00',
            color: 'white',
            fontWeight: 'bolder'
        },
        paper: {
            zIndex: 'auto'
        },
        tasksContainerSize: {
            width: '30em',
            padding: '0 1em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: AccountFull[];
    currentAccount: AccountFull | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    searchAccountName: string;
    storedAccounts: AccountFull[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
    mainSpinnerText: string;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isJobMenuOpen: boolean;
    isTeamsLoading: boolean;
    accountTeams: Team[];
    totalTeams: number;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
}

@inject('rootStore')
@observer
class UserCreation extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {};

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        searchAccountName: '',
        mainSpinnerText: 'Please wait. Loading data.',
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isJobMenuOpen: false,
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0,
        isTeamsLoading: false,
        accountTeams: [],
        totalTeams: 0,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: ''
    };

    accountProvider = new AccountProvider();
    userProvider = new UserProvider();
    jobProvider = new JobProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
        this.fetchRunningTasks();
        this.startTimeInterval();
    }

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.User_Creation);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach(element => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round(
                    (time - element.startedAt) / 1000
                );
            });
            this.setState({ runningTasks });
        }, 1000);
        this.setState({ timeElapser: timeElapserInterval });
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(
            e => e.page === MainTabs.User_Creation
        );
        this.setState(
            {
                runningTasks: tasks!.tasks
            },
            () => {
                tasks!.tasks.forEach(element => {
                    if (element.status === 102) {
                        // SET TO RESTART POOLING
                        element.set_to_stop = false;
                        // this.startTaskWatcher(element);
                    }
                });
            }
        );
    };

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }

            this.jobProvider
                .checkJobStatus(taskItem.task_id)
                .then(data => {
                    if (data.job_status === StatusTypes.Error) {
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({ runningTasks });
                    }
                    if (data.job_status === StatusTypes.Complete) {
                        taskItem.status = 200;
                        taskItem.progress = 100.0;
                        this.jobProvider
                            .checkJobResult(taskItem.task_id)
                            .then(res => {
                                const results = res.result as JobResultDataset;
                                taskItem.result_data = results;
                                runningTasks[taskIndex] = taskItem;
                                this.setState({ runningTasks });
                            })
                            .catch(error => {
                                const { status } = error.response;
                                // CHECK IF WE TIMEOUT OR NOT
                                if (status === 408 || status === 504) {
                                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                                    taskItem.timer += 1000;
                                    setTimeout(
                                        taskItem.internalCallback!,
                                        taskItem.timer,
                                        taskId
                                    );
                                    return;
                                }
                                taskItem.status = 500;
                                runningTasks[taskIndex] = taskItem;
                                this.setState({ runningTasks });
                            });
                    } else {
                        // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                        if (
                            taskItem.progress === data.estimate_percent_complete
                        ) {
                            taskItem.static_progress_count += 1;
                        }
                        taskItem.progress = data.estimate_percent_complete;
                        // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                        // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                        if (
                            taskItem.static_progress_count ===
                            STATIC_PROGRESS_LIMIT
                        ) {
                            taskItem.timer += 1000;
                            taskItem.static_progress_count = 0;
                        }
                        setTimeout(
                            taskItem.internalCallback!,
                            taskItem.timer,
                            taskId
                        );
                    }
                })
                .catch(error => {
                    const { status } = error.response;
                    // CHECK IF WE TIMEOUT OR NOT
                    if (status === 408 || status === 504) {
                        // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                        taskItem.timer += 1000;
                        setTimeout(
                            taskItem.internalCallback!,
                            taskItem.timer,
                            taskId
                        );
                        return;
                    }
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({ runningTasks });
                });
        };

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(
            e => e.task_id === task.task_id
        );
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({ runningTasks }, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    };

    fetchAccounts = (offset: number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider
            .getAllAccountsFullSchema(offset, limit)
            .then(results => {
                const { accounts, total_account } = results;
                if (!accounts) {
                    if (offset === 0) {
                        this.setState({ networkError: true });
                    }
                    return;
                }

                this.setState({
                    isAccountsLoading: false,
                    totalAccounts: total_account,
                    accounts,
                    currentAccountOffset: offset,
                    dataPageIsLoading: false
                });
            })
            .catch(error => {
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({ networkError: true });
            });
    };

    onAccountDataPageOverBoundaryReached = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                currentAccountOffset,
                totalAccounts,
                searchAccountName
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage =
                nextPage === Math.ceil(totalAccounts / ROWS_PER_PAGE) - 1;
            const newOffset = isFirstPage
                ? 0
                : isLastPage
                ? ACCOUNTS_PAGING_LIMIT *
                  Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                : boundary === PageBoundary.Upper
                ? currentAccountOffset + ACCOUNTS_PAGING_LIMIT
                : currentAccountOffset - ACCOUNTS_PAGING_LIMIT;
            this.setState({ isAccountsLoading: true });
            if (searchAccountName !== '') {
                this.searchAccounts(searchAccountName, newOffset);
                return;
            }
            this.fetchAccounts(newOffset)
                .then(() => resolve())
                .catch(() => reject());
        });
    };

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState(
            {
                isAccountsLoading: true,
                searchAccountName: searchName
            },
            () => {
                const searchOffset = offset ? offset : 0;
                return this.accountProvider
                    .searchAccountByNameFullSchema(
                        searchName,
                        searchOffset,
                        ACCOUNTS_PAGING_LIMIT
                    )
                    .then(results => {
                        const {
                            accounts: foundAccounts,
                            total_account
                        } = results;
                        if (!foundAccounts) {
                            if (currentAccountOffset === 0) {
                                this.setState({ networkError: true });
                            }
                            return;
                        }

                        // If we haven't stored the original list do it
                        // This prevents multiple searchs overriding each others
                        if (!storedAccounts.length) {
                            this.setState({
                                storedAccounts: accounts,
                                storedAccountTotalCount: totalAccounts,
                                storedAccountOffset: currentAccountOffset
                            });
                        }

                        this.setState({
                            isAccountsLoading: false,
                            totalAccounts: total_account,
                            accounts: foundAccounts,
                            currentAccountOffset: searchOffset
                        });
                    })
                    .catch(error => {
                        const { status } = error.response;
                        if (status === 401) {
                            const { onAuthError } = this.props;
                            if (!onAuthError) {
                                return;
                            }
                            onAuthError();
                            return;
                        }
                        this.setState({ networkError: true });
                    });
            }
        );
    };

    onJobMenuToggle = () => {
        const { isJobMenuOpen } = this.state;
        this.setState({ isJobMenuOpen: !isJobMenuOpen });
    };

    getJobCount = (needBoolean: Boolean) => {
        const { runningTasks } = this.state;

        const finishedCount = runningTasks.filter(e => e.status !== 102).length;
        const totalCount = runningTasks.length;

        if (needBoolean) {
            return finishedCount === totalCount;
        }

        return `${finishedCount}/${totalCount}`;
    };

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.User_Creation);
        this.setState({ runningTasks: pendingTasks });
    };

    onAccountClicked = (account: AccountFull) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account,
            totalTeams: 0,
            accountTeams: [],
            isTeamsLoading: true
        });
        this.fetchTeamsFromAccount(account);
    };

    fetchTeamsFromAccount = (account: AccountFull) => {
        return this.accountProvider
            .fetchTeamsFromAccountIdAdmin(account.account_id, 0, 100)
            .then(results => {
                const { teams } = results;
                if (!teams) {
                    this.setState({ networkError: true });
                    return;
                }

                this.setState({
                    isTeamsLoading: false,
                    accountTeams: teams,
                    totalTeams: teams.length
                });
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({ networkError: true });
            });
    };

    onErrorModalClicked = () => {
        this.setState({ isErrorModalOpened: false });
    };

    onFormSubmit = (userEntries: UserPayloadEntry[]) => {
        if (!userEntries || userEntries.length <= 0) {
            return;
        }
        this.setState({ dataPageIsLoading: true });
        const { currentAccount } = this.state;
        const {
            rootStore: { adminStore }
        } = this.props;

        if (!currentAccount) {
            return;
        }

        const apiPayload: BulkUserPayload = {
            account_id: currentAccount.account_id,
            user_entries: userEntries
        };
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount.account_id,
            job_type: `${JobTypes.BulkUserCreation}`,
            additional_params: { ...apiPayload }
        };

        this.setState({
            dataPageIsLoading: true
        });
        this.jobProvider
            .scheduleJob(jobPayload)
            .then(result => {
                const { runningTasks } = this.state;
                const newTask: RunningTasks = {
                    account: {
                        accountId: currentAccount.account_id,
                        name: currentAccount.name,
                        structures: currentAccount.structures,
                        webIdleSessionTimeoutSeconds: 0,
                        timestamp: new Date(currentAccount.timestamp!)
                    },
                    export_name: `Creating users for ${currentAccount.name}`,
                    export_id: result.job_id,
                    type: JobTypes.BulkUserCreation,
                    task_id: result.job_id,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                    set_to_stop: false
                };

                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(
                    runningTasks,
                    MainTabs.User_Creation
                );
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
                this.setState({
                    runningTasks,
                    isErrorModalOpened: true,
                    errorModalHeader: 'Success',
                    errorModalText: 'User creation job submitted succesfully'
                });
            })
            .catch(error => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error submitting job',
                    errorModalText: error.message
                });
            })
            .finally(() => {
                this.setState({
                    dataPageIsLoading: false
                });
            });
    };

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if (task.status === 200) {
            const jobResults = task.result_data as JobResultDataset;
            window.open(jobResults.url, '_blank');
            return;
        }
        this.jobProvider
            .checkJobError(task.task_id)
            .then(data => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            })
            .catch(error => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({ networkError: true });
            });
    };

    public render() {
        const { classes } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            mainSpinnerText,
            isAccountsLoading,
            totalAccounts,
            runningTasks,
            isJobMenuOpen,
            currentTasksOffset,
            accountTeams,
            isTeamsLoading,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText
        } = this.state;

        const currentAccountName = currentAccount ? currentAccount.name : '';
        const container =
            window !== undefined ? () => window.document.body : undefined;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountPicker
                        className={`${classes.animContainer}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                        onAccountSearch={this.searchAccounts}
                    />
                    <CCSpinner
                        label="Loading teams"
                        labelClassName={classes.mainSpinnerLabel}
                        className={classes.progressContainer}
                        loading={isTeamsLoading}
                        size={200}
                    >
                        <UserBulkCreationForm
                            className={classes.formHolder}
                            currentAccount={currentAccount}
                            accountTeams={accountTeams}
                            onFormSubmit={this.onFormSubmit}
                        />
                    </CCSpinner>
                </CCSpinner>
                {runningTasks && runningTasks.length > 0 && (
                    <React.Fragment>
                        <Button
                            className={classes.jobMenuButton}
                            variant="contained"
                            color="primary"
                            data-testid="marker-detail-job-drawer-button"
                            onClick={this.onJobMenuToggle}
                        >
                            Running Jobs
                            <Chip
                                label={this.getJobCount(false)}
                                className={`${
                                    this.getJobCount(true)
                                        ? classes.finishedBadge
                                        : classes.notFinishedBadge
                                }`}
                            />
                        </Button>
                        <Drawer
                            container={container}
                            variant={'temporary'}
                            anchor={'right'}
                            open={isJobMenuOpen}
                            onClose={this.onJobMenuToggle}
                            classes={{
                                paper: classes.paper
                            }}
                        >
                            <RunningTasksList
                                className={classes.tasksContainerSize}
                                tasks={runningTasks}
                                rowsPerPage={ROWS_PER_PAGE}
                                taskItemsOffset={currentTasksOffset}
                                listName="Running User Creation Operations"
                                onTaskClear={this.onTaskClear}
                                onDrawerClose={this.onJobMenuToggle}
                                onTaskClick={this.onTaskClick}
                            />
                        </Drawer>
                    </React.Fragment>
                )}
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                />
                {networkError && (
                    <SimpleModal
                        className={classes.errorPopup}
                        open={networkError}
                        contentClasses={classes.errorPopupContentContainer}
                        buttonOkLabel=""
                        buttonCancelLabel=""
                        header=""
                        headerClassName={classes.errorPopupHeader}
                    >
                        <Error
                            color="error"
                            className={classes.errorPopupIcon}
                        />
                        <div>
                            <Typography variant="h5">
                                {'Network Error'}
                            </Typography>
                            <Typography variant="subtitle1">
                                {'Please reload the page.'}
                            </Typography>
                        </div>
                    </SimpleModal>
                )}
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(UserCreation);
export { MUIComponent as UserCreation };
